<script lang="ts">
	import CloseIcon from '$components/UI/Icons/CloseIcon.svelte';
	import { getToastStore } from '$lib/stores';
	import { fly } from 'svelte/transition';

	const toasts = getToastStore();
</script>

{#if $toasts.length}
	<div class="toasts">
		{#each $toasts as toast}
			<div
				in:fly|global={{ y: -50 }}
				out:fly|global={{ y: 0 }}
				class="toast"
				class:toast-success={toast.type === 'success'}
				class:toast-error={toast.type === 'error'}
				class:toast-warning={toast.type === 'warning'}
			>
				<div class="toast-message">
					{@html toast.message}
				</div>
				{#if toast.dismissable}
					<div class="toast-close">
						<button type="button" on:click={() => toast.id && toasts.close(toast.id)}>
							<CloseIcon />
						</button>
					</div>
				{/if}
			</div>
		{/each}
	</div>
{/if}

<style>
	.toasts {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9999;
	}
	.toast {
		display: flex;
		padding: var(--spacing-4);
		line-height: var(--font-lineheight-1);
		color: var(--toasts-color);
		font-size: var(--toasts-font-size);
		background-color: var(--toasts-bg-color);

		&-message {
			flex: 1;
		}

		&-close {
			margin-left: auto;

			* {
				display: block;
			}
		}
	}
</style>
