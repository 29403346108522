<script lang="ts">
	import type { LayoutData } from './$types';
	import { browser } from '$app/environment';
	import { afterNavigate, beforeNavigate } from '$app/navigation';
	import { page as pageStore } from '$app/state';
	import Modal from '$components/UI/Modals/Modal.svelte';
	import Toasts from '$components/UI/Toasts/Toasts.svelte';
	import { maybeSetAdvocateId } from '$lib/affiliate';
	import { setSiteContext } from '$lib/context/site';
	import { initStores } from '$lib/stores';
	import { onMount } from 'svelte';

	let { children, data } = $props();

	// Initialize stores.
	const stores = initStores();

	// Set up context.
	setSiteContext(data.site);

	let pageType: string | undefined = $state(undefined);

	const etrustedReviews = pageStore.data?.page?.layout?.reviews?.etrusted ?? null;

	if (browser) {
		pageType = pageStore.data?.page?.type ?? null;
	}

	onMount(() => {
		Object.values(stores).forEach((store) => store.onMount?.());

		// Load GTM
		if (data.site.gtmCode) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
			const gtmScript = document.createElement('script');
			gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + data.site.gtmCode;
			document.head.append(gtmScript);
		}

		maybeSetAdvocateId();
	});

	beforeNavigate(({ to }) => {
		const urlHash = to?.url.hash;

		// Remove the smooth scroll class when navigating to regular URLS
		if (!urlHash && window) {
			window.document.documentElement.classList.remove('smooth-scroll');
		}
	});

	afterNavigate(() => {
		// Make sure the smooth-scroll class is always returned if it was
		// removed for any reason. See code above.
		if (window) {
			window.document.documentElement.classList.add('smooth-scroll');
		}
	});
</script>

<svelte:head>
	<link rel="icon" type="image/png" href={data.site.favicon} />

	{#if etrustedReviews?.id}
		<script
			async
			data-desktop-y-offset="0"
			data-mobile-y-offset="0"
			data-desktop-disable-reviews="false"
			data-desktop-enable-custom="false"
			data-desktop-position="right"
			data-desktop-custom-width="156"
			data-desktop-enable-fadeout="false"
			data-disable-mobile="false"
			data-disable-trustbadge="false"
			data-mobile-custom-width="156"
			data-mobile-disable-reviews="false"
			data-mobile-enable-custom="false"
			data-mobile-position="left"
			data-mobile-enable-topbar="false"
			data-mobile-enable-fadeout="true"
			data-color-scheme="light"
			charset="UTF-8"
			src={`//widgets.trustedshops.com/js/${etrustedReviews.id}.js`}
		>
		</script>
	{/if}
</svelte:head>

<div class="wrapper {pageType ? `type-${pageType}` : ''}">
	<Toasts />

	<Modal />

	{@render children()}
</div>

<style lang="postcss">
	@import '../styles/global/reset.css';
	@import '../styles/global/config.css';
	@import '../styles/global/base.css';
	@import '../styles/global/parts/slider.css';
	@import '../styles/global/parts/forms.css';
	@import '../styles/global/parts/block.css';
	@import '../styles/global/parts/table.css';
	@import '../styles/global/parts/columns.css';
	/* @import '../styles/havery/theme.css'; */
	/* @import '../styles/madee/theme.css'; */
	/* @import '../styles/dekoffiejongens/theme.css'; */
	@import '$theme';
</style>
