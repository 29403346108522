import { getCartStore, initCartStore } from '$lib/stores/cart';
import { getCheckoutStore, initCheckoutStore } from '$lib/stores/checkout';
import { getModalStore, initModalStore } from '$lib/stores/modal';
import { getToastStore, initToastStore } from '$lib/stores/toast';
import { getUserStore, initUserStore } from '$lib/stores/user';

export function initStores() {
	initCartStore();
	initCheckoutStore();
	initModalStore();
	initToastStore();
	initUserStore();
	return {
		cartStore: getCartStore(),
		checkoutStore: getCheckoutStore(),
		modalStore: getModalStore(),
		toastStore: getToastStore(),
		userStore: getUserStore()
	};
}

export { getCartStore } from '$lib/stores/cart';
export { getCheckoutStore } from '$lib/stores/checkout';
export { getModalStore } from '$lib/stores/modal';
export { getToastStore } from '$lib/stores/toast';
export { getUserStore } from '$lib/stores/user';
