<script lang="ts">
	import { getModalStore } from '$lib/stores';
	import { fade } from 'svelte/transition';

	const modals = getModalStore();

	function handleCancel() {
		if (modal && modal.response) {
			modal.response(false);
		}
		modals.close();
	}

	function handleConfirm() {
		if (modal && modal.response) {
			modal.response(true);
		}
		modals.close();
	}

	// Active modal is at index 0
	$: modal = $modals[0];
</script>

{#if $modals.length > 0}
	<!-- Active visible modal is at index 0 -->
	{@const modal = $modals[0]}

	<div class="modal" transition:fade={{ duration: 250 }}>
		<div class="modal-inner">
			<button type="button" class="modal-close" on:click={modals.close} />
			{#if modal.title}
				<h3 class="modal-title">
					{@html modal.title}
				</h3>
			{/if}

			{#if modal.body}
				<div class="modal-body rich-text">
					{@html modal.body}
				</div>
			{/if}

			{#if modal.type === 'component' && modal?.component?.ref}
				<svelte:component this={modal.component.ref} {...modal?.component?.props} {modal} />
			{/if}

			{#if modal.type === 'confirm' && modal.confirm !== undefined}
				<footer class="modal-buttons">
					<button type="button" class="modal-button button" on:click={handleCancel}>
						{modal.confirm?.cancelText}
					</button>
					<button type="button" class="modal-button button" on:click={handleConfirm}>
						{modal.confirm?.confirmText}
					</button>
				</footer>
			{/if}
		</div>
		<button class="modal-backdrop" on:click={modals.close} />
	</div>
{/if}

<style lang="postcss" global>
	.modal {
		& {
			position: fixed;
			z-index: 9998;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			overflow: auto;

			@media (--sm) {
				align-items: center;
			}
		}
		&-inner {
			position: relative;
			z-index: 2;
			padding: var(--spacing-6);
			background-color: white;
			width: 100%;
			max-width: 100%;
			margin: var(--spacing-4);

			@media (--sm) {
				width: auto;
				min-width: 540px;
				max-width: 640px;
				margin: 0 auto;
			}
		}
		&-title {
			position: relative;
			max-width: 75%;
			font-family: var(--modal-title-font-family);
			font-size: var(--modal-title-font-size);
			font-weight: var(--modal-title-font-weight);
			line-height: var(--modal-title-lineheight);
			text-transform: var(--modal-title-text-transform);
			margin-bottom: var(--modal-title-margin-bottom);

			@media (--xs) {
				max-width: 100%;
			}

			&-info {
				color: var(--color-primary);
				display: inline-block;
				margin-left: 2px;
			}
		}
		&-body {
			font-size: var(--modal-text-font-size);
			line-height: var(--modal-text-font-lineheight);
			margin-bottom: var(--spacing-5);

			p:not(:last-child) {
				margin-bottom: var(--spacing-4);
			}

			h2,
			h3,
			h4 {
				font-size: var(--font-size-2);
				line-height: var(--font-lineheight-1);
				margin-bottom: var(--spacing-1);
			}
		}
		&-info {
			margin-bottom: var(--spacing-5);
		}
		&-datepicker {
			margin-bottom: var(--spacing-8);
		}
		&-buttons {
			display: flex;
			flex-direction: column-reverse;

			@media (--sm) {
				flex-direction: row;
				justify-content: flex-end;
			}

			&:not(:last-child) {
				margin-bottom: var(--spacing-6);
			}

			&--align-left {
				justify-content: flex-start;
			}
		}

		&-button {
			width: 100%;
			margin-bottom: var(--spacing-3);

			@media (--sm) {
				width: auto;
				margin-bottom: 0;
			}

			&:not(:last-child) {
				@media (--sm) {
					margin-right: var(--spacing-3);
				}
			}

			&-cancel {
				margin-right: auto !important;
			}

			&-link {
				color: var(--color-grey-3);
				text-decoration: underline;
				font-weight: var(--font-weight-5);
				font-size: var(--font-size-0);
				transition: color var(--transition);

				&:hover {
					color: var(--color-grey-1);
				}
			}
		}
		/* currently only used to fix button alignment in step 2 of the reactivate modal */
		&-back {
			display: block;
			max-width: 50%;
			margin-top: -60px;
		}

		&-description {
			font-size: var(--font-size-0);
			line-height: var(--font-lineheight-2);
		}
		&-close {
			position: absolute;
			z-index: 2;
			right: var(--spacing-6);
			top: var(--spacing-6);
			width: var(--font-size-3);
			height: var(--font-size-3);
			opacity: 0.75;

			&:hover {
				opacity: 1;
			}
			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 9px;
				height: 21px;
				width: 2px;
				background-color: var(--color-black);
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}
		&-backdrop {
			display: block;
			position: fixed;
			z-index: 1;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.75);
		}
	}
</style>
